<template>
    <div class="onboarding-page">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'onboarding'
}
</script>

<style scaffold lang="scss">

</style>
